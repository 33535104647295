import { getAllValues } from "@lib/configcat";
import type { secretKey } from "@utils/get-config";
import type { OpCo } from "@utils/get-opco-with-locale";
import type { Session } from "next-auth";

export const SupportedOpCos: IdpOpCoSettings[] = [
  { opCo: "noordhoff", locale: "nl" },
  { opCo: "plantyn", locale: "nl-be" },
  { opCo: "plantyn", locale: "fr-be" },
  { opCo: "liber", locale: "sv" },
];

type IdpSetting = {
  id: string;
  clientId: string;
  issuer: string;
  usePrompt: boolean;
  secretEnv: secretKey;
  jwksEndpoint: string;
  scopes: string;
};

enum ConfigcatIdpKeys {
  Id = "idpId",
  ClientId = "idpClientId",
  UsePrompt = "idpUsePrompt",
  Issuer = "idpIssuer",
  JwksEndpoint = "idpJwksEndpoint",
  Scopes = "idpScopes",
}

type IdpOpCoSettings = {
  opCo: OpCo;
  locale: string;
  keys?: IdpSetting;
};

function getSecretKey(opCo: OpCo): secretKey {
  // Todo : This will be refactored after the release pipeline is modified
  if (opCo === "noordhoff") {
    return "IDP_CLIENT_SECRET";
  }
  return `IDP_CLIENT_SECRET_${opCo.toUpperCase()}` as secretKey;
}

async function getAllOpCoIdpSettingsFromConfigCat(): Promise<IdpOpCoSettings[]> {
  const retrievedConfigurations: IdpOpCoSettings[] = [];
  // biome-ignore lint/complexity/noForEach: opcos are hardcoded
  SupportedOpCos.forEach((config) => {
    retrievedConfigurations.push({ opCo: config.opCo, locale: config.locale });
  });

  const configCatOpCoIdpSettingsPromises = SupportedOpCos.map(async (config) => {
    return await getAllValues(config.opCo, undefined, { locale: config.locale });
  });

  const configCatOpCoIdpSettings = await Promise.all(configCatOpCoIdpSettingsPromises);

  configCatOpCoIdpSettings.map((value, index) => {
    retrievedConfigurations[index].keys = {
      id: value.find((setting) => setting.key === ConfigcatIdpKeys.Id)?.value,
      clientId: value.find((setting) => setting.key === ConfigcatIdpKeys.ClientId)?.value,
      issuer: value.find((setting) => setting.key === ConfigcatIdpKeys.Issuer)?.value,
      usePrompt: value.find((setting) => setting.key === ConfigcatIdpKeys.UsePrompt)?.value,
      secretEnv: getSecretKey(retrievedConfigurations[index].opCo),
      jwksEndpoint: value.find((setting) => setting.key === ConfigcatIdpKeys.JwksEndpoint)?.value,
      scopes: value.find((setting) => setting.key === ConfigcatIdpKeys.Scopes)?.value,
    };
  });

  return retrievedConfigurations;
}

export async function getIdpConfigByAud(aud: string): Promise<IdpSetting> {
  const idpConfigs = await getAllOpCoIdpSettingsFromConfigCat();
  const config = idpConfigs.find((config) => config.keys?.clientId === aud)?.keys;

  if (!config) throw `Missing IDP Config for ${aud}`;
  return config;
}

export async function getIdpConfigByOpCo(opCo: OpCo, locale: string): Promise<IdpSetting> {
  const idpConfigs = await getAllOpCoIdpSettingsFromConfigCat();

  let config = idpConfigs.find((config) => config.opCo === opCo && config.locale === locale);
  if (config?.keys) {
    return config.keys;
  }

  // fallback only needed for local development (using different locales, fe )
  config = idpConfigs.find((config) => config.opCo === opCo);

  if (!config || !config?.keys) {
    throw `Missing IDP Config for ${opCo}`;
  }

  return config.keys;
}

export async function getSignOutUrl(redirectUrl: string, session: Session) {
  const idpConfig = await getIdpConfigByAud(session.aud);

  const endSessionURL = `${idpConfig.issuer}/connect/endsession`;
  const endSessionParams = new URLSearchParams({
    id_token_hint: session.idToken ?? "",
    post_logout_redirect_uri: `${redirectUrl}/signout`,
  });

  return `${endSessionURL}?${endSessionParams}`;
}
